.myButton {
    background-color: #EBE8E1;
    transition: background-color 0.3s ease;
}
/* 
.myButton:hover {
    background-color: #D5D2C8;
} */

.copy-button {
    background-color: #EBE8E1;
    transition: background-color 0.3s ease;
  }
  
  .icon {
    margin-left: 15px;
  }
  
  .copied-button {
    background-color: #D5D2C8;
  }

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.confirm-button {
  animation: pulse 2s infinite;
}

.decline-button {
  animation: shake 0.5s infinite;
}
