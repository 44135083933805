@font-face {
    font-family: 'Nunito';
    src: url("./Nunito-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url("./Nunito-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url("./Nunito-ExtraBold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url("./Nunito-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

.black-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
}

.bold-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.extraBold-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
}

.semiBold-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}